.master-screen {
  background-image: url("/img/master-screen-bgd.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}
.master-screen-sentence {
  max-width: 90%;
  font-size: 8rem !important;
}
.master-screen-button {
  width: 274px;
  height: 106px;
  font-size: 3rem !important;
}

.quizz-page {
  height: 100%;
}

.quizz-question-page {
  height: 100%;
}

.quizz-question-container {
  min-height: 100%;
  padding-left: 100px !important;
  padding-right: 100px !important;
  padding-bottom: 100px;
}
.quizz-image-container {
  height: 100%;
}

.quizz-text-container {
  height: 100%;
  padding-bottom: 100px;
  font-size: 2rem;
}

.quizz-description {
  font-size: 1.8rem;
  line-height: 3.1rem;
}

.quizz-question {
  border-bottom-color: rgba(255, 95, 87, 0.28);
  border-bottom-width: 7px;
  border-bottom-style: solid;
  font-weight: 500;
  font-size: 1.9rem;
  line-height: 3.4rem;
}

.quizz-case-detail {
  padding: 20px;
  height: 65px;
  display: inline-table;
  text-align: center;
  background-color: #1a252b;
  color: white;
  vertical-align: middle;
  font-size: 2.2rem;
  line-height: 3.4rem;
}
.quizz-case-detail-correct {
  color: #38cb7a;
}
.quizz-case-detail-incorrect {
  color: #ff5f57;
}

.quizz-option {
  border: 1.5px solid #52545f !important;
  color: #1a252b !important;
  background-color: white;
  height: 90px;
  width: 100%;
  font-size: 2.2rem !important;
}

.quizz-option-wrong {
  border: 1.5px solid #ff5f57 !important;
  color: #ff5f57 !important;
}

.quizz-option-right {
  border: 1.5px solid #38cb7a !important;
  color: #38cb7a !important;
}

.quizz-nav-button {
  font-weight: 400 !important;
  height: 50px !important;
  width: 118px !important;
  background-color: #f1f1f1 !important;
  font-size: 2.2rem !important;
}

.quizz-reference {
  font-size: 1.5rem;
  line-height: 2.3rem;
}
