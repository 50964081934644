.quizz-option-grid-container {
  margin-top: 16px;
  padding-top: 40px;
  margin-bottom: 40px;
  position: relative;
}

.quizz-see-details-pill {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.quizz-see-details-pill button {
  text-decoration: none;
  border-radius: 20px;
  background-color: rgba(110, 155, 226, 0.2);
  color: rgb(94, 136, 201);
  padding: 8px 16px;
  font-size: 14px !important;
  font-weight: bold;
  border-width: 0;
}

@media only screen and (max-width: 478px) {
  .quizz-option-grid-container {
    padding-bottom: 100px;
  }
}
