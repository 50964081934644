html{
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root{
  height: 100%;
}

.hidden-mobile{
  display:none;
}
.hidden-web{
  display:block;
}
@media only screen and (min-width: 426px) {
  .hidden-mobile{
    display:block;
  }
  .hidden-web{
    display:none;
  }
}

.test{
  border: 1px solid black;
  color: red;
}
