.quizz-page {
  height: 100%;
}

.quizz-question-page {
  height: 100%;
}

.quizz-question-page .bubble-background {
  position: relative;
  overflow: hidden;
  background-color: #f7f7f7;
}

.quizz-question-page .bubble-background .red-circle {
  position: absolute;
  top: 10%;
  left: -500px;
  height: 1000px;
  width: 1000px;
  z-index: 1;
  border-radius: 9999px;
  background-color: rgba(255, 95, 87, 0.1);
}

.quizz-question-page .bubble-background .blue-circle {
  position: absolute;
  bottom: 10%;
  right: -500px;
  height: 1000px;
  width: 1000px;
  z-index: 1;
  border-radius: 9999px;
  background-color: rgba(151, 191, 191, 0.15);
}

.media-container {
  background-color: #dfebec;
}

.media-container video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background-size: cover;
}

.quizz-question-container {
  min-height: 100%;
  max-height: 100vh;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: 100px;
  background-color: white;
  padding-top: 16px;
  position: relative;
}

/* Mac magic to always show scrollbar pos OSX Lion */
/* This handles desktop view. Something similar is done for mobile but on body */
::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 10px;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  border: 2px solid #eee;
}

::-webkit-scrollbar-track {
  background-color: #eee;
}

@media only screen and (min-width: 426px) {
  .quizz-question-container {
    overflow-y: scroll;
  }
}

.quizz-image-container {
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.quizz-image-container img {
  width: 100%;
  max-height: 100vh;
}

.quizz-text-container {
  height: 100%;
  padding-bottom: 100px;
}

.quizz-description-container {
  position: relative;
  padding-bottom: 30px;
}

.quizz-description {
  font-size: 1.1rem;
  line-height: 26px;
}

.quizz-question {
  border-bottom-color: rgba(255, 95, 87, 0.28);
  border-bottom-width: 7px;
  border-bottom-style: solid;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  margin-top: 20px;
}

.quizz-case-detail {
  padding: 8px 12px;
  text-align: center;
  background-color: #37414a;
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.quizz-case-detail.poll {
  background-color: #6e9be2;
}

.quizz-case-detail.correct {
  background-color: #78b8bf;
}

.quizz-case-detail.incorrect {
  background-color: #e45a5a;
}

.quizz-navbar {
  padding-top: 10px;
  margin-bottom: 16px;
}

.quizz-nav-button {
  font-weight: 500 !important;
  height: 50px !important;
  width: 118px !important;
  background-color: #f1f1f1 !important;
  font-size: 1rem !important;
}

.quizz-reference {
  font-size: 12px;
}

.references-container {
  margin-top: 8px;
}

.references-container.absolute-position {
  position: absolute;
  bottom: 0px;
}

@media only screen and (max-width: 768px) {
  .quizz-image-container img {
    width: auto;
    margin: 0 auto;
  }
}

/* Hack to get fixed position relative to column on desktops. */
.nav-container {
  position: sticky;
  bottom: -100px;
  background-color: white;
}

@media only screen and (max-width: 478px) {
  .quizz-image-container img {
    width: 100%;
  }

  .quizz-description {
    font-size: 16px;
    line-height: 24px;
  }

  .nav-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
  }

  .nav-container .quizz-navbar {
    border-top: 1px solid rgb(216, 216, 216);
    margin: 0;
    background-color: white;
    padding: 16px 20px;
    padding-bottom: 24px;
  }

  .nav-container .quizz-navbar button {
    font-size: 16px !important;
    height: auto !important;
    width: auto !important;
  }
}
