.quizz-option {
    color: #1a252b !important;
    line-height: 1.25rem;
    box-shadow: 0px 2px 11px 1px rgba( 45, 55, 63, 0.19);
    background-color: white;
    height: 90px;
    width: 100%;
    font-size: 1rem;
    margin-top: 15px;
    position: relative;
    padding: 8px 20% !important;
    font-weight: bold !important;
}

@media only screen and (max-width: 478px) {
    .quizz-option {
        padding: 8px 20% !important;
    }
}


.quizz-option span {
    position: absolute;
    top: calc(50% - 12px);
    right: 24px;
    height: 24px;
    width: 24px;
}

.quizz-option:disabled {
    cursor: not-allowed;
}

.quiz-option-correction {
    color: rgba(45, 55, 63, 0.19);
    background-color: white !important;
    opacity: 1 !important;
}


.quizz-option-wrong {
    border: 2px solid #e45a5a !important;
    box-shadow: 0px 2px 11px 1px  rgba(228, 90, 90, 0.3);
    color: black !important;
    line-height: 1.25rem;
}

.quizz-option-right {
    border: 2px solid #78b8bf !important;
    box-shadow: 0px 2px 11px 1px  rgba( 120, 216, 191,  0.3);
    color: black !important;
    line-height: 1.25rem;
}

.quizz-option-poll {
    border: 2px solid #6e9be2 !important;
    box-shadow: 0px 2px 11px 1px  rgba( 120, 216, 191,  0.3);
    color: black !important;
    line-height: 1.25rem;
}