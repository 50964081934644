.not-supported-modal {
  z-index: 1;
  background-color: white;
  border-radius: 4px;
  padding: 40px 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  color: black;
  font-size: 20px;
  text-align: center;
}

.not-supported-modal h2 {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 16px;
}

/* IE only, need to explicitly define width to make text wrap in flex */
/* https://stackoverflow.com/questions/35111090/text-in-a-flex-container-doesnt-wrap-in-ie11 */
.not-supported-modal p {
  width: 100%;
}

.browser-logo-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.browser-logo-container .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.browser-logo-container .item:nth-of-type(2) {
  margin: 0 32px;
}

.browser-logo-container .browser-logo {
  width: 60px;
  margin-bottom: 8px;
}

@media only screen and (max-width: 478px) {
  .not-supported-modal {
    width: 90%;
  }

  .browser-logo-container {
    display: flex;
    margin-top: 16px;
  }
}

@media only screen and (max-width: 800px) {
  .not-supported-modal {
    width: 90%;
    padding: 40px 24px;
  }

  .browser-logo-container {
    display: flex;
    margin-top: 16px;
  }
}
