.master-screen {
  background-image: url("/img/master-screen-bgd.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.master-screen-sentence {
  max-width: 85%;
  font-size: 35px !important;
  font-family: HelveticaNeue-Thin;
  line-height: 43px;
}

.master-screen-sentence .score {
  display: inline;
  padding: 16px;
  font-size: 24px;
  font-family: HelveticaNeue;
  background-color: #e45a5a;
}

.master-screen-button {
  width: 159px;
  height: 62px;
  font-size: 18px !important;
}


@media only screen and (min-width: 426px) {
  .master-screen-sentence {
    line-height: 88px;
    font-size: 80px !important;
  }
  .master-screen-button {
    width: 200px;
    height: 75px;
    font-size: 22px !important;
  }
}

.moving-cell{
  background-color:rgba(0, 0, 0, 0.10);
  border-radius:50%;
  position:absolute;
  overflow: hidden;
}

.moving-cell-top{
  width:500px;
  height:500px;
  animation: moveCellTop 20s infinite;
  animation-direction:alternate;
  top:50px;
  left:-250px;
}

.moving-cell-bottom{
  width:100px;
  height:100px;
  animation: moveCellBottom 20s infinite;
  animation-direction:alternate;
  bottom:50px;
  right:100px;
}

/* Animation Keyframes*/
@keyframes moveCellTop {
  0% {
    transform: translate(20%, 20%);
  }
  50% {
    transform: translate(30%, 20%);
  }
  100% {
    transform: translate(10%, 0%);
  }
}
@keyframes moveCellBottom {
  0% {
    transform: translate(30%, 20%);
  }
  50% {
    transform: translate(-70%, -80%);
  }
  100% {
    transform: translate(-70%, -60%);
  }
}


@media only screen and (max-width: 478px) {
  .master-screen-sentence .score {
    font-size: 18px;
  }

  .moving-cell-top {
    width: 350px;
    height: 350px;
    animation: moveCellTop 20s infinite;
    animation-direction:alternate;
    top:50px;
    left:-250px;
  }
}